<template>
  <div class="MyOftenBuyBox">
    <el-row>
        <el-col :span="24"   class="titbox">
            <el-col :span="8">
              <span class="title">最近购买</span>
            </el-col>
        </el-col>
    </el-row>
    <p class="progressBar"></p>
    <div class="content">
        <div class="ItemBox">
          <div class="item"  v-for="(item, index) in ItemArr" :key="index">
              <div class="ItemLeft" >
                <div class="imgWrap"  @click="jumpDetail(item.goodsId,item.isRecentExpiration)">
                  <img v-if="item.goodsCount<Number(item.minOrderNum)" class="soldOut" alt="补货中" src="@/assets/soldOut.png" />
                  <img :alt="item.name | defaultName" :src="item.goodsPic+picSuffix | defaultImage" />
                </div>
              </div>
              <div class="ItemRight">
                <h3 class="shopName" :title="item.name">{{ item.goodsName | defaultName }} </h3>
                <div class="specification">规格:{{ item.partSpecification }}</div>
                <div class="specification">效期:{{ item.validDate }}</div>
                <div class="specification">
                  厂家:{{ item.manufacturerName }}
                </div>
                 <div class="hidePrice" v-show="!isqualification">
                    资质认证后显示商品价格
                  </div>
                  <div class="hidePrice" v-show="isqualification">
                    <span class="price">{{ item.originalPrice==="***"?"会员可见":item.originalPrice  | capitalize }}</span>
                  </div>
                  <div class="BuyCartBtn">
                        <div class="counter">
                          <InputNumber :item="item" ></InputNumber> 
                        </div>
                        <button :disabled="item.goodsCount<0" class="btnImg" @click="addCartFun(item)" v-log="[item.name, '点击加入购物车']" >
                          加入购物车
                        </button>
                  </div>
              </div>
          </div>

          <div class="clear"></div>
        </div>
    </div>
  </div>
</template>

<script>
import Qs from "qs";
import defaultImage from "@/assets/defaultImage.png";
import { oftenBuy } from "api/order.js";
import { AddCart} from "api/product.js";
const InputNumber = () => import("components/common/InputNumber.vue");
import { mapMutations, mapState } from "vuex";
  export default {
    name:"MyOftenBuy",
    data(){
        return{
          picSuffix:localStorage.getItem("productPic"),
          visible: false,
          ItemArr:'',
          defaultImage:defaultImage,
        }
    },
    methods:{
      jumpDetail(id,isRecentExpiration) {
        this.$router.push({ name: "ProductDetail", query: { id: id,isRecentExpiration:isRecentExpiration } });
      },
      ...mapMutations({
        setCarGoodsNum: "setCarGoodsNum",
      }),
   
  
        addCartFun(item) {
          // alert(11)
          // {"isRecentExpiration":0,"quantity":10,"productId":1}
          if(item.originalPrice==="***"){
              this.$message({
                  message:'请点击顶部右侧【手机商城】，扫码二维码下载商城App，上传认证信息',
                  type:"warning"
              })
              return false
          }
          const addcartDate = [];
           let memberStoreId=Qs.parse(sessionStorage.getItem("memberData")).memberStoreId
          addcartDate.push({
            isRecentExpiration:item.isRecentExpiration,
            quantity: item.num,
            productId: item.goodsId,
            goodNum:item.goodNum,
               memberStoreId:memberStoreId
          });
          // 加入购物车接口
          AddCart(addcartDate).then((data) => {
            // this.$router.push({ name: "Cart", query: { id: id } });
            if (data.data.code == 200) {
              // 提示信息
              this.$notify({
                title: "加入购物车",
                message: "成功",
                type: "success",
                duration: 1000,
              });
              this.setCarGoodsNum(item.num);
            } else {
              this.$notify({
                title: "提示信息",
                message: data.data.msg,
                type: "error",
                duration: 1000,
              });
            }
          });
        },

    },
     computed: {
    ...mapState({
      isqualification: (state) => state.isqualification,
    }),
  },
   created(){
    //  this.$route.params.type
    let datas={
      current:1,
      size:9
    }
    oftenBuy(datas).then(res=>{
        this.ItemArr=res.data.data.content
        console.log('oftenBuyuyoftenBuy',this.ItemArr)
        if(res.data.msg && !res.data.data.count){
           this.$message({
            message: res.data.msg,
            type: "warning",
          });
          return false
        }
        if(this.ItemArr!==undefined ){
            this.ItemArr.forEach((item) => {
              item.num = item.minOrderNum;
            });
        }

      })

    },
     components:{
        InputNumber
    }
  }
</script>

<style lang="less" scoped>
@import "~style/index.less";

.clear{
  clear: both;
}

 .titbox{
        // border-bottom:none;
        // border-left: 2px solid #FFD303;
        .title{
            font-size: 16px;
            line-height: 22px;
            padding-right: 13px;
            color:#333333;
            font-weight: 600;
            cursor: pointer;
            &.active{
              color:#FFD303
            }
            &:nth-child(2){
                color: #666;
                 &.active{
                  color:#FFD303
                }

            }
        }
    }

.MyOftenBuyBox {
  
  .progressBar {
    height: 20px;
    margin-left: -20px;
    width: 100%;
    background: #f6f6f6;
    padding: 0 20px;
    margin-top: 20px;
  }
  .fromWraper {
    padding: 20px 0;
      background: #FFD303;
    }
  }



  .content {
    position: relative;
   .ItemBox{
     background: #f6f6f6;
     width: calc(100% + 40px);
     height: auto;
     position: absolute;
     left: -20px;
     top: 0;

     .item{
       width: 316.6px;
       float: left;
       margin:0 10px 10px 0 ;
       background: #fff;
       .ItemLeft{
           float: left;
           .imgWrap {
              padding:10px;
              overflow: hidden;
              position: relative;
              width:110px;
                height:110px;
              .soldOut{
                display: block;
                  width:110px;
                height:110px;
                z-index: 2;
                position:absolute;
                //  top:10px;
                left:10px;
              }
              img {
                // display: inline-block;
                // width: 100%;
                // height: 150px;
                display: block;
                width:100%;
                height:100%;
                margin: 0 auto;
              }
            }
       }
       .ItemRight{
         float: left;
         width: calc(100% - 130px);
         padding: 10px 0;
         h3{
            height: 21px;
            font-size: 16px;
            font-family: MicrosoftYaHei;
            color: #333333;
            line-height: 21px;
            letter-spacing: 1px;
            white-space:nowrap;
            text-overflow:ellipsis;
            overflow:hidden;
         }
         .specification{
            height:20px;
            line-height:20px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 12px;
            color: #666;
         }
        .hidePrice {
            color: #f43c38;
            line-height:26px;
            padding-top:6px;
            .price {
              color: #f43c38;
              font-size:18px;
              line-height:26px;
                  font-weight: bold;
            }
        }

        .BuyCartBtn {
          height:30px;
          width:100%;
          .counter{
              float: left;
              margin-right: 10px;
          }
          .btnImg {
             float: left;
             cursor: pointer;
            width:80px;
            height:26px;
            padding:0;
            line-height: 25px;
            text-align: center;
            font-size: 14px;
            font-family: MicrosoftYaHei;
            color: #333333;
            width: 80px;
            background: #F7F7F7;
            border-radius: 3px;
            border: 1px solid #E5E5E5;
            &:hover{
               color: #FFD303;
                 border: 1px solid  #FFD303;
            }
          }
        }
       }
     }
    .item:nth-child(3n + 0) {
      margin-right: 0px;
    }
   }
}
</style>
